import React, { useEffect, createContext, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from './context/AuthContext';

function App() {

  const [token, setToken] = React.useState('');
  const [_user, setUser] = React.useState('' as any);

  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const [userMetadata, setUserMetadata] = React.useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://sbx.api.backerz.one',
            scope: "profile",
          },
        });
        setToken(accessToken);
        if(user) {
          setUser(user)
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    console.log(_user)
  }, [_user]);
  return <AuthContext.Provider value={{
    user: _user,
    token
    }}>
    <Dashboard />
  </AuthContext.Provider>
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
