import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../../internals/components/Copyright';
import ChartUserByCountry from '../ChartUserByCountry';
import CustomizedTreeView from '../CustomizedTreeView';
import CustomizedDataGrid from '../CustomizedDataGrid';
import HighlightedCard from '../HighlightedCard';
import PageViewsBarChart from '../PageViewsBarChart';
import SessionsChart from '../SessionsChart';
import StatCard, { StatCardProps } from '../StatCard';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Table from '../Table';

export default function TabsPage() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
      <Table />
      </Grid>
    </Box>
  );
}
