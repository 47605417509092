import * as React from 'react';
import { useContext, useEffect } from 'react';
import AuthContext from './context/AuthContext';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-charts/themeAugmentation';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from '@mui/x-tree-view/themeAugmentation';
import { alpha, Theme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import HomePage from './components/pages/HomePage';
import TabsPage from './components/pages/TabsPage';
// import HomePage from './components/pages/HomePage';
// import TabsPage from './components/pages/TabsPage';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import axios from 'axios';
import LoginButton from './components/LoginButton';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props: { disableCustomTheme?: boolean }) {
  const authContext = useContext(AuthContext);
  const [campaignDetails, setCampaignDetails] = React.useState([]);

  useEffect(() => {
    if (!authContext.token) return;
    (async () => {
      const { data } = await axios.get('https://sbx.api.backerz.one/v0.1/my-campaigns', {
        headers: {
          Authorization: `Bearer ${authContext.token}`,
          'X-Api-Key': 'kna793h790n5v26cbhjalof08cn03b77cv619nxg',
        },
      });
      setCampaignDetails(data);
      console.log(data);
    })();
  }, [authContext.token]);

  return (
    <Router>
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: 'flex' }}>


          {authContext?.user?.email && <SideMenu />}
          {authContext?.user?.email && <AppNavbar />}
          {/* Main content */}
          <Box
            component="main"
            sx={(theme: Theme) => ({
              flexGrow: 1,
              backgroundColor: alpha(theme.palette.background.default, 1),
              overflow: 'auto',
            })}
          >
            {!authContext?.user?.email && <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: 1,
                marginTop: 4,
                // backgroundColor: '#f9f9f9'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Login Required
              </Typography>
              <Typography variant="body1">
                Please log with Patron.
              </Typography>
              <LoginButton />
            </Box>}
            <Stack
              spacing={2}
              sx={{
                alignItems: 'center',
                mx: 3,
                pb: 5,
                mt: { xs: 8, md: 0 },
              }}
            >
              {authContext?.user?.email && <Header />}

              {authContext?.user?.email && <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/tabs" element={<TabsPage />} />
                {/* <Route exact={true} path="/manufacturer/profile/list" element={<ListManufacturerProfilesPage />} /> */}
              </Routes>}
              {/* <MainGrid /> */}
            </Stack>
          </Box>
        </Box>
      </AppTheme>
    </Router>
  );
}
